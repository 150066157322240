.home {
  height: auto;
  padding-top: 12rem;
  width: 100%;
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1547069535-d683b2164512?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-repeat: no-repeat;
}

.background-image {
  z-index: -1;
}

.intro {
  margin: 0 auto;
  overflow: hidden;
  width: 90vw;
}

.intro-content {
  display: flex;
  position: relative;
  justify-content: flex-start;
  padding-bottom: 8rem;
}

.intro-text {
  color: rgb(255, 255, 255);
  width: 54%;
  text-align: start;
}

.intro-text .greet {
  font-size: 2em;
  margin-bottom: 0.3rem;
  font-family: "Dancing Script", cursive;
  font-weight: 700;
  font-style: normal;
}

.intro-text .name {
  font-size: 4em;
  font-family: "Righteous", sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 1rem;
}

.intro-text .name span {
  color: rgb(136, 219, 255);
  font-weight: 900;
}

.intro-text .soft {
  font-size: 1.7rem;
  font-family: "Oleo Script", system-ui;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 3rem;
}
.buttons {
  display: flex;
  gap: 2rem;
  font-family: "Righteous", sans-serif;
}

.buttons .btn2 {
  padding: 0.8em 1.8em;
  border: 2px solid #020041;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  background: #0b003e;
}

.buttons .btn2::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -65%) rotate(55deg);
  background: #ffffff;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.buttons .btn2:hover::before {
  width: 105%;
}

.buttons .btn2:hover {
  color: #00013f;
}

.buttons .btn1 {
  padding: 0.8em 1.8em;
  border: 2px solid #580000;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  background: #600000;
}

.buttons .btn1::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #ffffff;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.buttons .btn1:hover::before {
  width: 105%;
}

.buttons .btn1:hover {
  color: #680000;
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .home {
    padding-top: 3rem;
    background-position: 35% 60%;
  }

  .intro {
    margin: 0 auto;
    overflow: inherit;
    width: 95vw;
  }

  .intro-content {
    padding-top: 10rem;
    width: 95vw;
    margin: 0 auto;
    padding-bottom: 10rem;
  }

  .intro-text {
    width: 60%;
  }

  .intro-text .greet {
    font-size: 2em;
    margin-bottom: 0.5rem;
  }

  .intro-text .name {
    font-size: 4em;
    margin-bottom: 1.3rem;
  }

  .intro-text .soft {
    font-size: 1.7em;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 799px) and (min-width: 480px) {
  .home {
    padding-top: 3rem;
    background-position: 50% 6%;
  }

  .intro {
    margin: 0 auto;
    overflow: hidden;
    width: 90vw;
  }

  .intro-content {
    padding-top: 8rem;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10rem;
    padding-left: 0;
    display: flex;
  }

  .intro-text {
    width: 90%;
  }

  .intro-text .greet {
    font-size: 2em;
    margin-bottom: 0.5rem;
  }

  .intro-text .name {
    font-size: 3.4em;
    margin-bottom: 1.3rem;
  }

  .intro-text .soft {
    font-size: 1.7em;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 480px) and (min-width: 70px) {
  .home {
    padding-top: 3rem;
    background-position: 50% 6%;
  }

  .intro {
    margin: 0 auto;
    overflow: hidden;
    width: 82vw;
  }

  .intro-content {
    padding-top: 5rem;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 7rem;
    padding-left: 0;
    display: flex;
  }

  .intro-text {
    width: 95%;
  }

  .intro-text .greet {
    font-size: 1.8em;
    margin-bottom: 0.3rem;
  }

  .intro-text .name {
    font-size: 2.9em;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .intro-text .soft {
    font-size: 1.2em;
    margin-bottom: 3rem;
  }

  .buttons .btn1,
  .buttons .btn2 {
    font-size: 0.8em;
  }
}
