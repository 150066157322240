.gallery {
  height: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.954);
}

.gallery-content {
  margin: 0 auto;
  width: 90vw;
}

.gallery-title {
  font-size: 2.2em;
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 2rem 0;
  font-family: "Oleo Script", system-ui;
  /* border-bottom: 2px solid rgba(0, 0, 0); */
}

.gallery-container {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 1rem;
  margin-bottom: 3rem;
}

.gallery-container .gallery-card {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 1rem;
}

.gallery-container .gallery-card:hover {
  box-shadow: 0 8px 16px rgba(0, 8, 72, 0.699);
  transform: scale(1.05);
}

.imgOpen {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 4, 19, 0.781); /* Adjust the opacity as needed */
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.imgOpen.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.imgOpen img {
  max-width: 90%;
  max-height: 90vh;
}

.imgOpen .close-icon {
  position: absolute;
  top: 1rem;
  font-size: 3em;
  font-weight: 800;
  right: 1rem;
  color: rgb(255, 255, 255);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.18);
}

.imgOpen .close-icon:hover {
  color: rgb(115, 0, 0);
  background-color: rgba(255, 255, 255, 0.18);
}

@media (max-width: 480px) {
  .gallery-container {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}
