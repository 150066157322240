.quotes {
  height: auto;
  width: 100%;
  background-image: url("https://images.pexels.com/photos/255419/pexels-photo-255419.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-repeat: no-repeat;
}

.quotes-container {
  margin: 0 auto;
  width: 60vw;
  overflow: hidden;
}

.quotes-content {
  margin: 4rem 0;
}

.quotes-content p {
  font-size: 2.2em;
  font-family: "Caveat", cursive;
  font-weight: 700;
  margin: 1rem 0;
  color: white;
}

.quotes-content h3 {
  font-size: 1.5em;
  margin-left: 55%;
  font-weight: 700;
  font-family: "Work Sans", sans-serif;
  font-style: italic;
  margin-top: 1rem;
  color: rgb(255, 255, 255);
}

@media only screen and (max-width: 480px) and (min-width: 50px) {
  .quotes-container {
    width: 70vw;
  }

  .quotes-content {
    margin: 4rem 0;
  }

  .quotes-content p {
    font-size: 2em;
  }

  .quotes-content h3 {
    font-size: 1.3em;
    margin-left: 30%;
  }
}
