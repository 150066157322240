.footer {
  height: auto;
  width: 100%;
  background-color: rgba(0, 8, 42, 0.954);
}

.main-footer {
  color: white;
  padding: 2em 0;
  margin: 0 auto;
  width: 90vw;
  overflow: hidden;
}

.row {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.col {
  flex-basis: 20%;
  padding: 1rem;
}

.footer-logo {
  font-size: 1.7em;
  font-family: "Oleo Script", system-ui;
  margin: 1rem 0;
}

.footer-logo span {
  color: rgb(127, 0, 0);
}

.footer-statement {
  font-size: 0.9em;
  font-family: "Work Sans", sans-serif;
}

.col h4 {
  font-size: 1.3em;
  margin: 1rem 0;
  font-family: "Righteous", sans-serif;
}

.col li {
  list-style: none;
  margin-bottom: 0.5rem;
  font-size: 1em;
  font-family: "Work Sans", sans-serif;
}

.col:nth-child(2),
.col:nth-child(3) {
  flex-basis: 15%;
}

.col:nth-child(4) {
  flex-basis: 11%;
  padding-top: 3rem;
}
.list-unstyled li a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
}

.list-unstyled li a:hover {
  color: #640000;
}

.footer-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px #640000;
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: rgb(0, 0, 57);
}

.footer-button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: #640000;
  align-items: center;
}

.footer-button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.footer-button::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.footer-button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}

hr {
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ffffff;
  margin: 1rem 0;
}

.col-sm {
  text-align: center;
  font-size: 0.9em;
  width: 100%;
}

.col-sm span {
  display: inline-block;
  animation: bounce 2s infinite;
  font-size: 1.3em;
}

.col-sm a {
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
  margin-left: 0.4rem;
}

.col-sm a:hover {
  text-decoration: none;
  color: #ff0000;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 490px) and (min-width: 70px) {
  .col,
  .col:nth-child(2),
  .col:nth-child(3),
  .col:nth-child(4) {
    flex-basis: 100%;
  }
}
