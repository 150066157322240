.section-about-info {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-info-container {
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  height: auto;
}

.about-info-container h2 {
  font-size: 1.6em;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-family: "Oleo Script", system-ui;
  text-align: center;
}

.about-boxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
}

.about-top-boxes,
.about-bottom-boxes {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  max-width: 100%;
  height: 280px;
  justify-content: center;
}

/* first box */

.box1 {
  background: #410000 0% 0% no-repeat padding-box;
  width: 350px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.box1:hover {
  background-color: #270000de;
  cursor: pointer;
}

.box-text {
  padding: 40px 20px;
  text-align: left;
  position: relative;
  z-index: 1;
  color: white;
}

.box-text h3 {
  font-size: 1.5em;
  margin-bottom: 1rem;
  font-family: "Righteous", sans-serif;
}

.box-text p {
  font-family: "Work Sans", sans-serif;
  font-size: 1em;
  font-weight: 500;
  font-style: normal;
}

/* second box */
.box2 {
  background-color: #000d36;
  width: 650px;
  height: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.box2:hover {
  background-color: #000925e8;
  cursor: pointer;
}

/* third box */
.box3 {
  background-color: #000d36;
  width: 650px;
  height: 100%;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.box3:hover {
  background-color: #000925e8;
  cursor: pointer;
}

/* fourth box */

.box4 {
  background: #410000;
  width: 350px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.box4:hover {
  background-color: #270000de;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .about-info-container {
    width: 100%;
    margin-bottom: 4rem;
  }

  .about-top-boxes,
  .about-bottom-boxes {
    display: flex;
    flex-wrap: nowrap;
    height: fit-content;
  }

  .about-top-boxes .box1,
  .about-top-boxes .box2,
  .about-bottom-boxes .box3,
  .about-bottom-boxes .box4 {
    width: 50%;
    height: fit-content;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 799px) and (min-width: 490px) {
  .about-info-container {
    width: 100%;
    margin-bottom: 4rem;
  }

  .about-top-boxes,
  .about-bottom-boxes {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
  }

  .about-top-boxes .box1,
  .about-top-boxes .box2,
  .about-bottom-boxes .box3,
  .about-bottom-boxes .box4 {
    width: 100%;
    height: fit-content;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 490px) and (min-width: 70px) {
  .section-about-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about-info-container {
    width: 100%;
    margin-bottom: 4rem;
  }

  .about-boxes {
    padding: 0;
  }

  .about-top-boxes,
  .about-bottom-boxes {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
  }

  .about-top-boxes .box1,
  .about-top-boxes .box2,
  .about-bottom-boxes .box3,
  .about-bottom-boxes .box4 {
    width: 100%;
    height: fit-content;
    margin: 0.5rem;
  }
}
