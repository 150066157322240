.about {
  height: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.954);
}

.about-container {
  margin: 0 auto;
  width: 90vw;
  overflow: hidden;
}

.about-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 6rem;
  margin-bottom: 1rem;
}

.about-title {
  font-size: 2.2em;
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 2rem 0;
  font-family: "Oleo Script", system-ui;
}

.about-text {
  flex: 1;
  height: auto;
}

.about-intro {
  margin-bottom: 1em;
  padding-top: 4rem;
  font-size: 1.7rem;
  text-align: start;
  color: rgb(106, 0, 0);
  font-family: "Righteous", sans-serif;
}

.about-intro span {
  color: rgb(0, 0, 87);
}

.about-text p {
  text-align: start;
  font-size: 1.5em;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.img-content {
  flex: 1;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: auto;
  max-width: 26rem;
}

.img-content img {
  width: 400px;
  height: 100%;
  box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
    0 0 50px rgba(142, 153, 157, 0.897);
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .about-container {
    margin: 0 auto;
    width: 95vw;
    padding-top: 2rem;
  }

  .about-content {
    gap: 3rem;
    margin-bottom: 3rem;
  }

  .about-intro {
    margin-bottom: 1em;
    padding-top: 3rem;
  }
}

@media only screen and (max-width: 799px) and (min-width: 490px) {
  .about-container {
    margin: 0 auto;
    width: 90vw;
    padding-top: 2rem;
  }

  .about-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-bottom: 6rem;
    align-items: center;
  }

  .about-title {
    margin: 1rem 0;
  }

  .about-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    margin-top: 0.1rem;
    align-items: center;
  }

  .about-intro {
    margin-bottom: 0.1em;
    padding-top: 0.1rem;
    margin-top: 0.1rem;
  }

  .img-content {
    flex: 1;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    width: auto;
    max-width: 25rem;
    background-color: rgb(0, 0, 76);
  }

  .img-content img {
    width: 370px;
    height: 95%;
    box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
      0 0 50px rgba(142, 153, 157, 0.897);
  }
}

@media only screen and (max-width: 490px) and (min-width: 70px) {
  .about-container {
    margin: 0 auto;
    width: 82vw;
    padding-top: 1rem;
  }

  .about-title {
    text-align: center;
    margin: 1rem 0;
  }

  .about-content {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: center;
    margin-top: 1rem;
  }

  .about-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    margin-top: 0.1rem;
    align-items: center;
  }

  .about-intro {
    margin-bottom: 0.1em;
    padding-top: 0.1rem;
    margin-top: 0.1rem;
  }

  .about-text p {
    text-align: center;
    margin-top: 0.5rem;
  }

  .img-content {
    flex: 1;
    justify-content: flex-start;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    width: auto;
    max-width: 15rem;
    background-color: rgb(0, 0, 76);
  }

  .img-content img {
    width: 260px;
    height: 100%;
    box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
      0 0 50px rgba(142, 153, 157, 0.897);
  }
}
