.navbar {
  height: 3.5rem;
  width: 100%;
  color: rgb(0, 0, 0);
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 2;
  text-decoration: none;
  background-color: #ffffff;
  box-sizing: border-box;
}

.navbar-content {
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  cursor: pointer;
}

.logo span {
  color: #ac0000;
}

.logo span span {
  color: rgb(0, 7, 81);
}

.nav-menu {
  display: flex;
  align-items: center;
}

.menu-item {
  margin: 1rem;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-size: 1.4em;
  cursor: pointer;
  font-weight: 500;
}

.menu-item:hover {
  color: rgb(0, 7, 148);
  padding-bottom: 0.4rem;
  border-bottom: 4px solid rgb(140, 0, 0);
}

.menu-item.active {
  padding-bottom: 0.2rem;
  border-bottom: 4px solid rgb(140, 0, 0);
}

.menu-list button {
  padding: 10px 20px;
  border: none;
  font-size: 1em;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  background: #720026;
  box-shadow: 0 0 25px rgb(255, 0, 0);
}

.menu-list button:hover {
  box-shadow: 0 0 5px rgb(255, 0, 0), 0 0 25px rgb(127, 0, 0),
    0 0 50px rgb(67, 1, 1), 0 0 100px rgb(255, 0, 0);
  color: #720026;
  background: #ffffff;
}

.menu-toggle {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .navbar {
    position: fixed;
    width: 100%;
  }

  .navbar-content {
    width: 95vw;
  }

  .menu-toggle {
    visibility: visible;
    opacity: 1;
  }

  .menu-list {
    display: none;
  }

  .menu-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    left: 0;
    height: auto;
    width: 100%;
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    padding-left: 0;
  }

  .menu-toggle-close {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .menu-item {
    font-size: 1.3em;
    padding-top: 1.5rem;
    margin: 0;
  }

  .menu-item:hover {
    color: rgb(0, 14, 59);
    border-bottom: 4px solid rgb(140, 0, 0);
  }

  .menu-toggle.open .menu-toggle-close {
    display: block;
  }

  .menu-item:last-child {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 799px) and (min-width: 480px) {
  .navbar {
    position: fixed;
    width: 100%;
  }

  .navbar-content {
    width: 90vw;
  }

  .menu-toggle {
    visibility: visible;
    opacity: 1;
  }

  .menu-list {
    display: none;
  }

  .menu-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    left: 0;
    height: auto;
    width: 100%;
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    padding-left: 0;
  }

  .menu-toggle-close {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .menu-item {
    font-size: 1.3em;
    padding-top: 1.5rem;
    margin: 0;
  }

  .menu-item:hover {
    color: rgb(0, 14, 59);
    border-bottom: 4px solid rgb(140, 0, 0);
  }

  .menu-toggle.open .menu-toggle-close {
    display: block;
  }

  .menu-item:last-child {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 480px) and (min-width: 70px) {
  .navbar {
    position: fixed;
    width: 100%;
  }

  .navbar-content {
    width: 82vw;
  }

  .menu-toggle {
    visibility: visible;
    opacity: 1;
  }

  .menu-list {
    display: none;
  }

  .menu-list.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    left: 0;
    height: auto;
    width: 100%;
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    padding-left: 0;
  }

  .menu-toggle-close {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .menu-item {
    font-size: 1.3em;
    padding-top: 1.5rem;
    margin: 0;
  }

  .menu-item:hover {
    color: rgb(0, 14, 59);
    border-bottom: 4px solid rgb(140, 0, 0);
  }

  .menu-toggle.open .menu-toggle-close {
    display: block;
  }

  .menu-item:last-child {
    margin-top: 1.5rem;
  }
}
