.contact {
  height: auto;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.954);
}

.contact-container {
  margin: 0 auto;
  width: 90vw;
  overflow: hidden;
}

.contact-title {
  font-size: 2.2em;
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 2rem 0;
  font-family: "Oleo Script", system-ui;
}

.contact-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 3rem;
  margin-bottom: 5rem;
}

.contact-text {
  flex: 1;
  margin-top: 3rem;
}

.contact-statement {
  margin-bottom: 2rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  font-size: 1.3em;
}

.img-contact {
  flex: 1;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.img-contact img {
  width: 550px;
  height: 100%;
  box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
    0 0 50px rgba(142, 153, 157, 0.897);
}

.social-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
  gap: 1.5rem;
  margin-left: 1rem;
}

.social-link {
  margin: 0;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.social-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: #000b48;
}

.social-link span {
  font-size: 1.2rem;
  color: #000000;
  font-family: "Righteous", sans-serif;
}

.social-link:hover {
  color: #340000;
}

@media only screen and (max-width: 1100px) and (min-width: 800px) {
  .contact-container {
    margin: 0 auto;
    width: 95vw;
    overflow: hidden;
  }

  .contact-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
  }

  .img-contact {
    flex: 1;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  .img-contact img {
    width: 650px;
    height: 100%;
    box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
      0 0 50px rgba(142, 153, 157, 0.897);
  }

  .contact-text {
    flex: 1;
    margin-top: 1rem;
  }

  .social-links {
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 799px) and (min-width: 490px) {
  .contact-container {
    margin: 0 auto;
    width: 90vw;
    overflow: hidden;
  }

  .contact-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .img-contact {
    flex: 1;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  .img-contact img {
    width: 400px;
    height: 100%;
    box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
      0 0 50px rgba(142, 153, 157, 0.897);
  }

  .contact-text {
    flex: 1;
    margin-top: 1rem;
  }

  .social-links {
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 490px) and (min-width: 70px) {
  .contact-container {
    margin: 0 auto;
    width: 82vw;
    overflow: hidden;
  }

  .contact-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .img-contact {
    flex: 1;
    justify-content: center;
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
  }

  .img-contact img {
    width: 300px;
    height: 100%;
    box-shadow: 0 0 25px rgba(88, 88, 88, 0.726),
      0 0 50px rgba(142, 153, 157, 0.897);
  }

  .contact-text {
    flex: 1;
    margin-top: 0.2rem;
  }

  .social-links {
    align-items: center;
    text-align: center;
  }

  .social-icon {
    font-size: 1.8rem;
  }

  .social-link span {
    font-size: 1rem;
  }
}
