.causes {
  background-color: #eeeeeebb;
  margin-bottom: 2rem;
}

.causes-title {
  font-size: 1.6em;
  padding-top: 1.5rem;
  margin-bottom: 0.4rem;
  font-family: "Oleo Script", system-ui;
  text-align: center;
}

.causes-slogan {
  font-size: 2em;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  font-family: "Righteous", sans-serif;
  text-align: center;
}

.causes h2 span {
  font-size: 1em;
  color: #700000;
  font-family: "Dancing Script", cursive;
}

.about-cont {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 4rem;
  width: 100%;
  height: auto;
  padding-bottom: 3rem;
}

.card {
  position: relative;
  width: 350px;
  height: 250px;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  perspective: 1000px;
  box-shadow: 0 0 0 5px #ffffff80;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}

.card img {
  width: 100%;
  height: 100%;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.card-icon {
  margin: 1rem 0;
  font-size: 2.2em;
  color: rgb(176, 0, 0);
}

.card .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000735;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card:hover .card-content {
  transform: rotateX(0deg);
}

.card-title {
  margin: 0;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  font-family: "Righteous", sans-serif;
}

.card:hover img {
  scale: 0;
}

.card-description {
  margin: 10px 0 0;
  font-size: 1em;
  color: #ffffff;
  line-height: 1.4;
  font-family: "Oleo Script", system-ui;
}
