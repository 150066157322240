.events {
  height: auto;
  width: 100%;
  background-color: rgba(236, 236, 236, 0.954);
}

.events-container {
  margin: 0 auto;
  width: 70vw;
}

.events-title {
  font-size: 2.2em;
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 2rem 0;
  font-family: "Oleo Script", system-ui;
}

.events-content {
  margin-bottom: 3rem;
  background-color: rgb(0, 3, 38);
  border-radius: 4rem;
}
.events-text {
  padding: 2rem;
  margin: 0;
  color: rgb(255, 255, 255);
  width: 80%;
}

.events-text h3 {
  font-size: 1.6em;
  font-family: "Righteous", sans-serif;
  margin-bottom: 0.6rem;
}

.events-text p {
  font-size: 1em;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  margin-bottom: 0.8rem;
  margin-top: 0;
}

.events-text h4 {
  margin-top: 0;
  font-size: 1em;
  font-family: "Dancing Script", cursive;
}

.slider-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  height: auto;
  padding-bottom: 3rem;
}

.event-card {
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  width: 200px;
}

.event-card img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.event-card img:hover {
  box-shadow: 0 8px 16px rgba(0, 8, 72, 0.699);
  transform: scale(1.05);
}

@media only screen and (max-width: 799px) and (min-width: 80px) {
  .events-container {
    margin: 0 auto;
    width: 90vw;
  }

  .events-text {
    padding: 1rem;
    margin: 0;
    color: rgb(255, 255, 255);
    width: 90%;
  }
}
